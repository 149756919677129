<template>
  <el-dialog
    title="分配负责人"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <table border="1">
      <thead>
        <tr>
          <th>姓名</th>
          <th>账户</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in accountantsList" :key="index">
          <td>{{ item.realName }}</td>
          <td>{{ item.userName }}</td>
          <td>
            <el-button type="primary" @click="edit(item)">确定</el-button>
          </td>
        </tr>
      </tbody>
    </table>
  </el-dialog>
</template>

<script>
import { selectAccounting, updateAccount } from "@/api/ChargeUp";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialogVisible: true,
      accountantsList: [], //会计人员列表
      identityIds: [], //需求人员列表
    };
  },
  computed: {
    ...mapGetters(["identityObj"]),
  },

  mounted() {
    //查询会计人员接口
    this.selectAccounting();
  },

  methods: {
    // 取消弹窗按钮
    Cancel() {
      this.$emit("closeDialog", false);
    },
    // 会计主管查询当前部门下的会计
    async selectAccounting() {
      let res = await selectAccounting();
      this.accountantsList = res.data;
    },
    //点击关闭弹窗
    handleClose() {
      this.$emit("closeDialog", false);
    },
    //配置列表[{}]
    init(item) {
      item.filter((item) => {
        let user = {};
        user.userId = item.userId;
        user.identityId = item.identityId;

        return this.identityIds.push(user);
      });
      // console.log(this.identityIds, "需求人Id");
    },
    //进行修改
    async edit(item) {
      console.log(item, "isGood");
      let params = [];
      this.identityIds.forEach((its) => {
        params.push({
          identityId: its.identityId,
          userId: its.userId,
          accountingId: item.userId,
        });
      });
      console.log(params, "这是params");
      let res = await updateAccount(params);
      this.$emit("closeDialog", false);
      if (res.code == 0) {
        this.$message.success("配置成功");
      } else {
        this.$message.info("配置失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  tr {
    td {
      text-align: center;
      border-collapse: collapse;
    }
  }
}
</style>
